import React, { forwardRef, useState, useRef, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom';
import classNames from 'classnames'
import { ConfigContext } from '../config'


type OptionLayerProps = {
    visible: boolean
    children: React.ReactNode
    showOption: React.Dispatch<React.SetStateAction<boolean>>
};

const OptionLayer = forwardRef<HTMLDivElement, OptionLayerProps>((props, ref) => {

    const {
        children,
        showOption,
        ...rest
    } = props;

    const [isVisible, setIsVisible] = useState(props.visible)

    const hiddenLayer = () => {
        // divRef.current?.classList.add('close')
        showOption(false)
    }

    const divRef = useRef<HTMLDivElement>(null);

    const options: JSX.Element = (
        <>
            <div className='backdrop' ref={divRef}>
                <div className='visible' onClick={hiddenLayer}></div>
                {children}
            </div>
        </>
    )


    useEffect(() => {
        setIsVisible(props.visible)
        return () => {
            
        }
    },[isVisible])
    

    return isVisible ? ReactDOM.createPortal(options, document.body) : null;
})



type variantType = 'inside' | 'outline';
type sizeType = 'default' | 'sm' | 'md' | 'lg' | 'xl';
type statusType = 'success' | 'warning' | 'error' | '';
type optionsPositionType = 'bottom' | 'middle' | '';



export interface OptionProps extends Omit<React.OptionHTMLAttributes<HTMLLIElement>, ''> {
    size?: sizeType
    status?: statusType | string
    prefixClass?: string
    selectClick?: any
}

export const Option = forwardRef<HTMLLIElement, OptionProps>((props, ref) => {
    const {
        id,
        label,
        className,
        value,
        disabled,
        prefixClass: customizePrefixClass,
        status,
        children,
        onClick,
        selectClick,
        ...rest
    } = props;


    const liRef = useRef<HTMLLIElement>(null)

    const closeOption = (callback:() => void) => {
        liRef.current?.parentElement?.parentElement?.classList.add('close')
        callback()
    }

    return (
        <>
            <li
                ref={liRef}
                className={className}
                onClick={(e) => selectClick({value, children, closeOption})} 
            >
                {children}
            </li>
        </>
    )
})
Option.displayName = 'Option'



export interface SelectProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    variant?: variantType
    size?: sizeType
    label?: string | React.ReactNode
    block?: boolean
    status?: statusType | string
    prefixClass?: string
    optionsPosition?: optionsPositionType
    contentClassName?: string
    getValue: React.Dispatch<React.SetStateAction<string>>
    getSelectedElement: React.Dispatch<React.SetStateAction<any>>
    selectedElement?: JSX.Element
}

const Select = forwardRef<HTMLDivElement, SelectProps>((props, ref) => {
    const {
        id,
        variant,
        label,
        size,
        className,
        contentClassName,
        autoFocus,
        color,
        value,
        block,
        disabled,
        placeholder,
        optionsPosition,
        prefixClass: customizePrefixClass,
        status,
        children,
        onBlur,
        onChange,
        onFocus,
        onClick,
        getValue,
        getSelectedElement,
        selectedElement,
        ...rest
    } = props;
    const { getPrefixClass, direction } = useContext(ConfigContext);
    const prefixClass = getPrefixClass('select', customizePrefixClass);
    const inputRef = useRef<HTMLInputElement>(null);
    const [currentStatus, setCurrentStatus] = useState(props.status);
    const [currentValue, setCurrentValue] = useState(value);
    const [focus, setFocus] = useState(autoFocus ? true : false);
    const [hasValue, setHasValue] = useState(value ? true : false);
    const [placeholderContent, setPlaceholderContent] = useState(variant === 'outline' ? '' : placeholder);
    const [optionTop, setOptionTop] = useState(0);
    const [optionLeft, setOptionLeft] = useState(0);
    const [optionWidth, setOptionWidth] = useState(0);
    const [optionHeight, setOptionHeight] = useState<number | string>('auto');
    const [showOption, setShowOption] = useState(false);
    const [selectContent, setSelectContent] = useState<string | React.ReactNode>(selectedElement);

    // const [children, setChildren] = useState(props.children)
    const wrapperClasses = classNames(
        {
            [`${prefixClass}-${variant}`]: variant,
            [`${prefixClass}-block`]: block,
            [`${prefixClass}-${size}`]: size,
            [`${prefixClass}-rtl`]: direction === 'rtl',
            [`${prefixClass}-disabled`]: disabled,
        }
    )

    const formControlClasses = classNames(
        `${prefixClass}-form-control`,
        {
            [`${prefixClass}-${currentStatus}`]: currentStatus !== '' ,
            [`${prefixClass}-focused`]: focus,
            [`${prefixClass}-${size}`]: size && size !== 'default',
            [`${prefixClass}-disabled`]: disabled,
        }
    )

    const labelControlClasses = classNames(
        `${prefixClass}-label`,
        {
            [`${prefixClass}-${currentStatus}`]: currentStatus !== undefined && currentStatus !=='',
            [`${prefixClass}-focused`]: focus,
            [`${prefixClass}-shrink`]: hasValue,
            [`${prefixClass}-${size}`]: size && size !== 'default',
            [`${prefixClass}-disabled`]: disabled,
        }
    )


    const inputClasses = classNames(
        prefixClass,
        {
            [`${prefixClass}-${size}`]: size && size !== 'default',
        },
        className
    )


    const legendClasses = classNames(
        prefixClass + 'legend',
        {
            [`${prefixClass}-legend-shrink`]: !focus && !hasValue,
            [`${prefixClass}-${size}`]: size && size !== 'default',
        }
    )

    const OptionWrapperClasses = classNames(
        prefixClass + '-options-wrapper',
        {
            [`${prefixClass}-options-${size}`]: size && size !== 'default',
        }
    )

    const SelectContentClasses = classNames(
        prefixClass + '-select-content',
        {
            [`${contentClassName}`]: contentClassName,
        }
    )

    useEffect(() => {
        if (value !== '') {
            setHasValue(true)
        }
    },[])
   
    
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        // removePasswordTimeout();
        setFocus(true);
        setShowOption(true)
        if (variant === 'outline' && !hasValue) {
            setPlaceholderContent(placeholder)
        }
        resize()
        onFocus?.(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocus(false);
        if (!showOption) {
            setShowOption(false)
        }
        if (variant === 'outline' && !hasValue) {
            setPlaceholderContent('');
        }
        onBlur?.(e);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
    };

    useEffect(() => {
        getValue(currentValue as React.SetStateAction<string>)
    }, [currentValue])
    

    useEffect(() => {
        if (value) {
            setHasValue(true)
        } else {
            setHasValue(false)
        }
    }, [selectContent, value])
    
    // 选中处理
    type selectValueType = {
        value: string
        children: React.ReactNode | string | undefined
        closeOption: (callback?:() => void) => void
    }
    
    const selectClick = (e: selectValueType) => {

        setCurrentValue(e.value)
        setHasValue(true)
        getSelectedElement(e.children)
        setSelectContent(e.children)
        e.closeOption(() => {
            setShowOption(false)
        })

    }


    useEffect(() => {
        setCurrentStatus(status)
    }, [status])
    


    const resize = () => {
        const clientHeight = document.documentElement.clientHeight
        const maxHeight = clientHeight / 2
        const selectElement = inputRef.current?.parentElement?.parentElement?.getBoundingClientRect()


        const topDistance = (selectElement?.y ?? 0) - maxHeight / 2 + (selectElement?.height ?? 0) / 2


        const selectElementTop = optionsPosition !== 'middle' ? (selectElement?.y ?? 0) : ( topDistance > 0 ? topDistance : 50)
        const selectElementHeight = selectElement?.height ?? 0
        const top = (selectElementTop + selectElementHeight) ?? 0



        if (top + maxHeight > clientHeight) {
            setOptionHeight(clientHeight - top - 50)
        } else {
            setOptionHeight('auto')
        }




        setOptionTop(top)
        setOptionLeft(selectElement?.x ?? 0)
        setOptionWidth(selectElement?.width ?? 0)
        // setOptionHeight()
    }

    window.onresize = function () {
        resize()
    }
    
    useEffect(() => {
        resize()
    }, [optionsPosition, showOption])

    return (
        <div className={`${prefixClass}-wrapper ` + wrapperClasses} ref={ref}>
            {label && variant !== 'inside' ?
                (
                    <label
                        className={labelControlClasses}
                        htmlFor={id}
                    >
                        {label}
                    </label>
                ) : false
            }
            <div className={formControlClasses}>
                {label && variant === 'inside' ?
                    (
                        <label
                            className={labelControlClasses}
                            htmlFor={id}
                        >
                            {typeof label === 'string' ? <span>{label}</span> : label}
                        </label>
                    ) : false
                }
                <input
                    {...rest as React.InputHTMLAttributes<HTMLInputElement>}
                    id={id}
                    autoComplete='false'
                    autoFocus={autoFocus}
                    className={inputClasses}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={value}
                    disabled={disabled}
                    aria-hidden='true'
                    ref={inputRef}
                />
                <div className={SelectContentClasses}>{selectedElement}</div>
                {showOption && <OptionLayer visible={showOption} showOption={setShowOption}>
                    <div className={OptionWrapperClasses} style={{ top: optionTop, left: optionLeft, width: optionWidth, height: optionHeight }}>
                        <ul>
                            <>
                                {
                                    React.Children.map(props.children, child => (
                                        React.cloneElement(child as React.ReactElement<any>, {
                                            defaultValue: value,
                                            selectClick,
                                        })
                                    ))
                                }
                            </>
                        </ul>
                    </div>
                </OptionLayer>}
                {variant === 'outline' ?
                    (
                        <fieldset className={`${prefixClass}-fieldset`}>
                            <legend className={legendClasses}><span>{label}</span></legend>
                        </fieldset>
                    ) : false
                }
                <div className='arrow'><svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowIcon"><path d="M7 10l5 5 5-5z"></path></svg></div>
            </div>
        </div>
    );
});


Select.displayName = 'Select';
export default Select;


