import { createSlice } from '@reduxjs/toolkit'
export interface StoreApp {
    cookieTip: boolean,
    theme: string,
    guest: boolean
}

const appSlice = createSlice({
    name: 'app',
    initialState: {
        cookieTip: false, // 是否弹出 cookie 提示
        theme: 'default', // 使用主题
        guest: true // 是否访客（未登录）
    },
    // 非异步调用
    reducers: {
        updateCookieTip: (state, action) => {
            state.cookieTip = action.payload
        },
        updateTheme: (state, action) => {
            state.theme = action.payload
        },
        updateGuest: (state, action) => {
            state.guest = action.payload
        }
    },
})

export const { updateCookieTip, updateTheme, updateGuest } = appSlice.actions
export default appSlice.reducer
