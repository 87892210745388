import React, { useContext } from 'react'
import classNames from 'classnames'
import useFlexGapSupport from '../common/useFlexGapSupport';
import { tuple, Breakpoint, ScreenMap, responsiveArray } from '../common'
import RowContext from './RowContext';
import { ConfigContext } from '../config'

const RowAligns = tuple('top', 'middle', 'bottom', 'stretch');
const RowJustify = tuple('start', 'end', 'center', 'space-around', 'space-between', 'space-evenly');




type Gap = number | undefined;
export type Gutter = number | undefined | Partial<Record<Breakpoint, number>>;

export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
    gutter?: Gutter| [Gutter, Gutter];
    align?: typeof RowAligns[number];
    justify?: typeof RowJustify[number];
    prefixClass?: string;
    wrap?: boolean;
}

const Row = React.forwardRef<HTMLDivElement, RowProps>((props, ref) => {
    const {
        prefixClass: customizePrefixClass,
        justify,
        align,
        className,
        style,
        children,
        gutter = 0,
        wrap,
        ...others
    } = props;

    const [screens] = React.useState<ScreenMap>({
        xs: true,
        sm: true,
        md: true,
        lg: true,
        xl: true,
        xxl: true,
    });

    const supportFlexGap = useFlexGapSupport();


    const getGutter = (): [Gap, Gap] => {
        const results: [Gap, Gap] = [undefined, undefined];
        const normalizedGutter = Array.isArray(gutter) ? gutter : [gutter, undefined];
        normalizedGutter.forEach((g, index) => {
            if (typeof g === 'object') {
                for (let i = 0; i < responsiveArray.length; i++) {
                    const breakpoint: Breakpoint = responsiveArray[i];
                    if (screens[breakpoint] && g[breakpoint] !== undefined) {
                        results[index] = g[breakpoint] as number;
                        break;
                    }
                }
            } else {
                results[index] = g;
            }
        });
        return results;
    };

    const {getPrefixClass, direction} = useContext(ConfigContext)
    const prefixClass = getPrefixClass('row', customizePrefixClass);
    const gutters = getGutter();
    
    const rowStyle: React.CSSProperties = {};
    const horizontalGutter = gutters[0] != null && gutters[0] > 0 ? gutters[0] / -2 : undefined;
    const verticalGutter = gutters[1] != null && gutters[1] > 0 ? gutters[1] / -2 : undefined;

    const classes = classNames(
        prefixClass,
        {
            [`${prefixClass}-no-wrap`]: wrap === false,
            [`${prefixClass}-${justify}`]: justify,
            [`${prefixClass}-${align}`]: align,
            [`${prefixClass}-rtl`]: direction === 'rtl',
        },
        className,
    );
    
    
    if (supportFlexGap) {
        // Set gap direct if flex gap support
        [, rowStyle.rowGap] = gutters;
    } else if (verticalGutter) {
        rowStyle.marginTop = verticalGutter;
        rowStyle.marginBottom = verticalGutter;
    }
    
    if (horizontalGutter) {
        rowStyle.marginLeft = horizontalGutter;
        rowStyle.marginRight = horizontalGutter;
    }

    const [gutterH, gutterV] = gutters;
    const rowContext = React.useMemo(
        () => ({ gutter: [gutterH, gutterV] as [number, number], wrap, supportFlexGap}),
        [gutterH, gutterV, wrap, supportFlexGap],
    );
    return (
        <RowContext.Provider value={rowContext}>
            <div {...others} className={classes} style={{ ...rowStyle, ...style }}  ref={ref}>
                {children}
            </div>
        </RowContext.Provider>
    );
});
    

    
export default Row;


