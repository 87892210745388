const Language = [
    { code: 'bg', name: 'български' },// 保加利亚语
    { code: 'cs', name: 'Čeština' },// 捷克语
    { code: 'da', name: 'Dansk' },// 丹麦语
    { code: 'de', name: 'Deutsch' },// 德语
    { code: 'el', name: 'Ελληνικά' },// 希腊语
    { code: 'en', name: 'English' },// 英语
    { code: 'es', name: 'Español' },// 西班牙语-西班牙
    { code: 'fi', name: 'Suomi' },// 芬兰语
    { code: 'fr', name: 'Français' },// 法语
    { code: 'hu', name: 'Magyar' },// 匈牙利语
    { code: 'id', name: 'Bahasa Indonesia' },// 印尼语
    { code: 'it', name: 'Italiano' },// 意大利语
    { code: 'ja', name: '日本語' },// 日语
    { code: 'km', name: 'ភាសាខ្មែរ' },// 高棉语
    { code: 'ko', name: '한국어' },// 韩语
    { code: 'lo', name: 'ພາສາລາວ' },// 老挝语
    { code: 'mn', name: 'монгол хэл' },// 蒙古语
    { code: 'my', name: 'မြန်မာဘာသာ' },// 缅甸语
    { code: 'ne', name: 'नेपाली' },// 尼泊尔语
    { code: 'nl', name: 'Nederlands' },// 荷兰语
    { code: 'no', name: 'Norsk' },// 挪威语
    { code: 'pl', name: 'Polski' },// 波兰语
    { code: 'pt', name: 'Português' },// 葡萄牙语-葡萄牙
    { code: 'ro', name: 'Română' },// 罗马尼亚语
    { code: 'ru', name: 'Русский' },// 俄语
    { code: 'sv', name: 'Svenska' },// 瑞典语
    { code: 'th', name: 'ภาษาไทย' },// 泰语
    { code: 'tr', name: 'Türkçe' },// 土耳其语
    { code: 'uk', name: 'Українська' },// 乌克兰语
    { code: 'vi', name: 'Tiếng việt' }, // 越南语
    { code: 'zh-CN', name: '简体中文' },
    { code: 'zh-HK', name: '繁體中文 (香港)' },
    { code: 'zh-TW', name: '繁體中文 (台灣)' },
]

export const tagToLanguageName = (tag: string | null) => {
    let name = ''
    switch (tag) {
        case 'bg': name = 'български'; break;
        case 'cs': name = 'Čeština'; break;
        case 'da': name = 'Dansk'; break;
        case 'de': name = 'Deutsch'; break;
        case 'el': name = 'Ελληνικά'; break;
        case 'en': name = 'English'; break;
        case 'es': name = 'Español'; break;
        case 'fi': name = 'Suomi'; break;
        case 'fr': name = 'Français'; break;
        case 'hu': name = 'Magyar'; break;
        case 'id': name = 'Bahasa Indonesia'; break;
        case 'it': name = 'Italiano'; break;
        case 'ja': name = '日本語'; break;
        case 'km': name = 'ភាសាខ្មែរ'; break;
        case 'ko': name = '한국어'; break;
        case 'lo': name = 'ພາສາລາວ'; break;
        case 'mn': name = 'монгол хэл'; break;
        case 'my': name = 'မြန်မာဘာသာ'; break;
        case 'ne': name = 'नेपाली'; break;
        case 'nl': name = 'Nederlands'; break;
        case 'no': name = 'Norsk'; break;
        case 'pl': name = 'Polski'; break;
        case 'pt': name = 'Português'; break;
        case 'ro': name = 'Română'; break;
        case 'ru': name = 'Русский'; break;
        case 'sv': name = 'Svenska'; break;
        case 'th': name = 'ภาษาไทย'; break;
        case 'tr': name = 'Türkçe'; break;
        case 'uk': name = 'Українська'; break;
        case 'vi': name = 'Tiếng việt'; break;
        case 'zh-CN': name = '简体中文'; break;
        case 'zh-HK': name = '繁體中文 (香港)'; break;
        case 'zh-TW': name = '繁體中文 (台灣)'; break;
    }
    return name;
}


// 读取 html 标签中的 lang 来加载语言
export const LanguageCode = document.documentElement.lang
export const LanguageTag = document.documentElement.lang.toLowerCase()

export default Language;