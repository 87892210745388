import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import axios from 'axios';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { LanguageTag } from '@/Language'
import appUrl, { Csrf } from '@/config'
import Url from '@/functions/createurl';
import './common.less';
import { Button, Container } from '@/rcui';
import { useAppDispatch, useAppSelector } from '@/store'
import { getOnline, StoreUser } from '@/store/features/user'
import CookieLayer from './CookieLayer';
import Dropdown from '@/functions/dropdown';


const Header: React.FC<{showCookieBar: boolean, setShowCookieBar: React.Dispatch<React.SetStateAction<boolean>>, setHeaderHeight: React.Dispatch<React.SetStateAction<number>>}> = (props) => {
    const {showCookieBar, setShowCookieBar, setHeaderHeight} = props
    const { t } = useTranslation();
    const [pageWidth, setPageWidth] = useState(document.body.clientWidth)
    const [showOverlay, setShowOverlay] = useState(false)

    const user: StoreUser = useAppSelector(state => state.userReducer as StoreUser)
    const dispatch = useAppDispatch();

    // cookie 弹出框
    const [cookieLayerShow, setCookieLayerShow] = useState('')
    
    const [NavJSX, setNavJSX] = useState(<></>)

    // 窗口大小改变
    window.onresize = function () {
        setPageWidth(document.body.clientWidth)
        // setBodyPaddingTop()
        navBoxHeight()
        subNavBoxHeight()
    }

    // const setBodyPaddingTop = () => {
    //     $('.header').hasClass('shrink') ? document.getElementsByTagName('body')[0].setAttribute('style', `padding-top:${document.getElementsByClassName('header')[0].clientHeight + 38}px`) : document.getElementsByTagName('body')[0].setAttribute('style', `padding-top:${document.getElementsByClassName('header')[0].clientHeight}px`)
    // }


    const calculationNavWidth = () => {
        let shrink = false
        if ($('.header').hasClass('shrink')) {
            shrink = true;
            $('.header').removeClass('shrink')
        }

        let NavMenu = $('.global-nav .nav').innerWidth() ?? 0
        let SubNavMenu = ($('.website-title').innerWidth() ?? 0) + ($('.model-nav .nav').innerWidth() ?? 0)
        let LogoDivWidth = $('.logo').innerWidth() ?? 0;
        let NavBarWidth = NavMenu >= SubNavMenu ? NavMenu : SubNavMenu;
        let RightBarWidth = $('.right-bar').innerWidth() ?? 0;
        let HeaderWrapper = $('.header-container').width() ?? 0;
        let HeaderClass = document.getElementsByTagName('header')[0].className
        
        if (LogoDivWidth + NavBarWidth + RightBarWidth > HeaderWrapper) {
            if (!new RegExp('shrink').test(' ' + HeaderClass + ' ')) {
                if (!$('.header').hasClass('shrink')) {
                    $('.header').addClass('shrink');
                }
                if ($('.header').hasClass('expand')) {
                    $('.header').removeClass('expand');
                }
            }
        } else {
            if (!new RegExp('expand').test(' ' + HeaderClass + ' ')) {
                if (!$('.header').hasClass('expand')) {
                    $('.header').addClass('expand');
                }
                if ($('.header').hasClass('shrink')) {
                    $('.header').removeClass('shrink');
                }
            }
        }
    }

    // 网站 header 自动展开和收缩
    useEffect(() => {
        setHeaderHeight($('header.header').height() as number)
        return () => {
            calculationNavWidth()
        }
    }, [pageWidth]);


    useEffect(() => {
        dispatch(getOnline())

        // 获取主导航
        axios.get(`${appUrl.www}/nav?language=${LanguageTag}`).then((res) => {
            let nav=[]
            for (const key in res.data.data) {
                if (false === res.data.data[key] instanceof Array) {
                    let navChild = []
                    for (const i in res.data.data[key]) {
                        navChild.push(
                            <React.Fragment key={i}><li><a className='link-outline' href={Url(res.data.data[key][i][0], res.data.data[key][i][1] ?? 'account', LanguageTag)} title={i}>{i}</a></li></React.Fragment>
                        )
                    }
                    nav.push(
                        <React.Fragment key={key}><li className='dropdown' data-responsive='payment-delivery' area-expanded='false' onClick={(e) => { Dropdown(e) }}>
                            <button className='dropdown-toggle dropdown-title link-outline' data-toggle='dropdown' role='button' aria-haspopup='true'>{key}</button>
                            <ul className='dropdown-box no-bullet payment-delivery' id='payment-delivery' data-toggle='dropdown'>{navChild}</ul>
                        </li></React.Fragment>
                    )
                } else {
                    nav.push(<React.Fragment key={key}><li><a className='link-outline' href={Url(res.data.data[key][0], res.data.data[key][1] ?? 'www', LanguageTag)} title={key}>{key}</a></li></React.Fragment>)
                }
                setNavJSX(<>{nav}</>)
            }
        })



        axios.get(`${appUrl.www}/cookies`).then((res) => {
            if (res.data.data.length === 0) {
                setShowCookieBar(true)
            }
        })

    }, []);

    useEffect(() => {
        calculationNavWidth()
        navBoxHeight()
        subNavBoxHeight()
    }, [NavJSX])


    const showNav = () => {
        $('.navbar-toggle').removeClass('active-remove').addClass('active-add');
        $('.global-nav').removeClass('active-remove').addClass('active-add');
        if (!$('.navbar-overlay').length) {
            setShowOverlay(true)
        };
        
        $('body').addClass('noscroll');
    }
    
    const hideNav = () => {
        $('.navbar-toggle').removeClass('active-add').removeClass('active').addClass('active-remove');
        $('.global-nav').removeClass('active-add').removeClass('active').addClass('active-remove');
        setShowOverlay(false)

        $('body').removeClass('noscroll');
    }

    if(document.getElementsByClassName('navbar-toggle').length){
        let _navbartoggle = document.getElementsByClassName('navbar-toggle')[0].childNodes[0];
        const _navbarToggleStart = () => {
            var t = $('.navbar-toggle');
            if (t.hasClass('active-add')) {
                t.removeClass('active-remove');
            }
            if (t.hasClass('active-remove')) {
                t.removeClass('active'); t.removeClass('active-add');
            }
        }
        const _navbarToggleEnd = () => {
            var t = $('.navbar-toggle');
            if (t.hasClass('active-add')) {
                t.removeClass('active-add').addClass('active');
            }
            if (t.hasClass('active-remove')) {
                t.removeClass('active-remove');
            }
        }
        _navbartoggle.addEventListener('webkitAnimationStart',_navbarToggleStart);
        _navbartoggle.addEventListener('animationstart',_navbarToggleStart);
        _navbartoggle.addEventListener('webkitAnimationEnd',_navbarToggleEnd);
        _navbartoggle.addEventListener('animationend',_navbarToggleEnd);
        
    }
    if(document.getElementsByClassName('global-nav').length){
        let _navbar = document.getElementsByClassName('global-nav')[0];
        const _navbarStart = () => {
            let t = $('.global-nav');
            if (t.hasClass('active-add')) {
                t.removeClass('active-remove');
            }
            if (t.hasClass('active-remove')) {
                t.removeClass('active');
                t.removeClass('active-add');
            }
        }
        const _navbarEnd = () => {
            let t = $('.global-nav');
            if (t.hasClass('active-add')) {
                t.removeClass('active-add').addClass('active');
            }
            if (t.hasClass('active-remove')) {
                t.removeClass('active-remove');
            }
        }
        _navbar.addEventListener('webkitAnimationStart',_navbarStart);
        _navbar.addEventListener('animationstart',_navbarStart);
        _navbar.addEventListener('webkitAnimationEnd',_navbarEnd);
        _navbar.addEventListener('animationend',_navbarEnd);
        
    }

    const navbarToggle = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        let t = $(e.currentTarget);
        
        t.hasClass('active') ? hideNav() : showNav();
    }


    // 收缩模式下的模块导航控制
    const modelNavToggle = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if ($('.header').hasClass('shrink')) {
            e.stopPropagation();
            let t=$(e.currentTarget).parent();
            if(t.hasClass('open')){
                t.removeClass('open').attr('area-expanded', 'false');
                $('body').removeClass('noscroll');
            }else{
                t.addClass('open').attr('area-expanded','true');
                $('body').addClass('noscroll');
            }
            $(document).one('click',function(){
                t.removeClass('open').attr('area-expanded','false')
            });
            t.find('.'+t.attr('data-responsive')).on('click',function(e){
                e.stopPropagation();
            });
        }
    }

    // 收缩模式下，全局导航的高度
    const navBoxHeight = () => {
        if ($('.header').hasClass('shrink')) {
            let height = window.innerHeight - document.getElementsByClassName('header-wrapper')[0]?.getBoundingClientRect().top
            $('.global-nav').css({ height: height})
            $('.global-nav .nav-wrapper').css({ height: height - 80 })
            $('.navbar-overlay').css({ height: height })
        } else {
            $('.global-nav').css({ height: 'auto'})
            $('.global-nav .nav-wrapper').css({ height: 'auto' })
            $('.navbar-overlay').css({ height: 'auto' })
        }
        
    }

    // 收缩模式下，模块导航的高度
    const subNavBoxHeight = () => {
        if ($('.header').hasClass('shrink')) {
            let maxHeight = window.innerHeight - 37 - document.getElementsByClassName('model-nav')[0]?.getBoundingClientRect().top
            $('.model-nav .nav').css({ maxHeight: maxHeight })
        }
    }


    const acceptCookie = () => {
        axios.post(`${appUrl.www}/cookies`, new URLSearchParams({analytics: '1', socialMedia: '1', advertising: '1'}), { headers: { 'Content-Type' : 'application/x-www-form-urlencoded', 'X-CSRF-Token' : Csrf.token }, withCredentials: true}).then((res) => {
            setShowCookieBar(false)
        })
    }


    const rejectCookie = () => {
        axios.post(`${appUrl.www}/cookies`, new URLSearchParams({analytics: '0', socialMedia: '0', advertising: '0'}), { headers: { 'Content-Type' : 'application/x-www-form-urlencoded', 'X-CSRF-Token' : Csrf.token }, withCredentials: true}).then((res) => {
            setShowCookieBar(false)
        })
    }
    

    return (
        <React.Suspense fallback='loading'>
            <header className='header'>
                {showCookieBar ? <div className='cookie-banner'>
                    <div className='cookie-description'>
                        <div><span className='iconfont'>&#xe740;</span></div>
                        <div><Trans i18nKey='CookieTip' components={{ a: <a className='link-outline' href={Url('privacy-statement', 'privacy', LanguageTag)} /> }} /></div>
                    </div>
                    <div className='cookie-buttons'>
                        <Button type='button' outline onClick={(e) => { acceptCookie() }}>{t('Accept')}</Button>
                        <Button type='button' outline onClick={(e) => { rejectCookie() }}>{t('Reject')}</Button>
                        <Button type='button' outline onClick={() => { setCookieLayerShow('cookie'); }}>{t('ManageCookies')}</Button>
                    </div>
                </div> :'' }
                <div className='header-wrapper'>
                    {showOverlay ? <div className='navbar-overlay' onClick={(e) => {hideNav()}}></div> : false}
                    <Container className='header-container'>
                        <div className='navbar-toggle-wrapper'><span className='navbar-toggle' onClick={(e)=>{navbarToggle(e)}}><i></i></span></div>
                        <div className='logo'>
                            <a className='link-outline' href={Url('', 'www', LanguageTag)} title={t('Roanchen')}><img src={`${appUrl.cdn}/images/full_logo.svg`} alt='Roanchen Logo' /></a>
                        </div>
                        <div className='nav-bar global-nav show'>
                            <div className='nav-wrapper'>
                                <ul className='nav'>
                                    {NavJSX}
                                </ul>
                            </div>
                        </div>
                        <div className='right-bar'>
                            <ul className='nav'>
                                <li className='header-search-wrapper'><a className='link-outline iconfont' title={t('Search')}>&#xe601;</a></li>
                                {user.online
                                    ?
                                    <li className='nav-userbar'>
                                        <div className='userbar-wrapper'>
                                            <div className='userbar' tabIndex={0} title={user.nickname}>
                                                <img className='avatar' src={user.avatar?`${appUrl.static}/avatar/${user.avatar}`:`${appUrl.cdn}/images/placeholder.png`} />
                                                <span className='nickname'>{user.nickname}</span>
                                            </div>
                                            <div className='usercard' tabIndex={0}>
                                                <div className='usercard-header'>
                                                    <img className='logo' src={`${appUrl.cdn}/images/full_logo.svg`} />
                                                    <a className='signout link-outline' href={Url('signout', 'account', LanguageTag)}>{t('SignOut')}</a>
                                                    <a className='wallet link-outline' href={Url('payments', 'account', LanguageTag)}>¥ {user.wallet}</a>
                                              
                                                </div>
                                                <div className='usercard-main'>
                                                    <a className='avatar' href={Url('profile/edit-avatar', 'account', LanguageTag)}>
                                                        <img src={user.avatar ? `${appUrl.static}/avatar/${user.avatar}` : `${appUrl.cdn}/images/placeholder.png`} />
                                                        <span className='iconfont'>&#xe8f4;</span>
                                                    </a>
                                                    <div className='usercard-item'>
                                                        <div className='nickname'>{user.nickname}</div>
                                                        <div className='rcid'>{user.roanchenid}</div>
                                                        <div className='ctrl'>
                                                            <a className='link-outline' href={Url('profile', 'account', LanguageTag)}>{t('MyProfile')}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    :
                                    <li><a className='link-outline iconfont' title={t('SignIn')} href={Url('signin', 'account', LanguageTag)}>&#xe617;</a></li>
                                }
                            </ul>
                        </div>
                        </Container>
                    </div>
            </header>
            {cookieLayerShow ? <CookieLayer setModel={setCookieLayerShow} /> : false}
        </React.Suspense>
    )
};

export default Header