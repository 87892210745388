import React, { useContext } from 'react'
import { ConfigContext } from '../config'

interface MediaAttributes {
    width: number
    src: string
}

export interface ImageProps extends React.HTMLAttributes<HTMLPictureElement> {
    media: MediaAttributes[]
    image: string
}

const Image = React.forwardRef<unknown, ImageProps>((props, ref) => {
    const {
        media,
        image,
        title,
        ...others
    } = props
    const Ref = (ref as any) || React.createRef<HTMLElement>();

    return (
        <picture ref={Ref}>
            {media.map((img, i) => {
                return <source key={i} media={`(min-width: ${img.width}px)`} srcSet={img.src} />
            })}
            <img src={image}  alt={title}/>
        </picture>
    )
});

Image.displayName = 'Image';
export default Image;