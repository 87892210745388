import React from 'react';
import $ from 'jquery';

const Dropdown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    let t=$(e.currentTarget);
    if(t.hasClass('open')){
        t.removeClass('open').attr('area-expanded','false');
    }else{
        t.addClass('open').attr('area-expanded','true');
    }
    $(document).one('click',function(){
        t.removeClass('open').attr('area-expanded','false')
    });
    t.find('.'+t.attr('data-responsive')).on('click',function(e){
        e.stopPropagation();
    });

};

export default Dropdown;

