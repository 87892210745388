import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from '@/reportWebVitals';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next, Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { LanguageTag } from '@/Language'
import app, { Csrf } from '@/config'
import Url from '@/functions/createurl';
// import '@/components/style/index.less';
// import './profile.less';
import { Button, Radio} from '@/rcui';
import EditLayer, { backPathname } from '@/functions/editLayer';
import { enableScroll } from '@/functions/scroll';
interface cookieType {
    analytics: number | null,
    socialMedia: number | null,
    advertising: number | null
}

const CookieLayer: React.FC<{ setModel: React.Dispatch<React.SetStateAction<string>>}> = (props) => {
    const { t } = useTranslation();
    const {setModel} = props
    const [errorMsg, setErrorMsg] = useState('');
    const [analytics, setAnalytics] = useState<number|null>(null)
    const [socialMedia, setSocialMedia] = useState<number|null>(null)
    const [advertising, setAdvertising] = useState<number|null>(null)

    useEffect(() => {

    }, [])


    const changeAnalytics = (e: React.ChangeEvent<HTMLInputElement>, v: number) => {
        v ? setAnalytics(e.target.checked ? 1 : 0) : setAnalytics(e.target.checked ? 0 : 1)
    }

    const changeSocialMedia = (e: React.ChangeEvent<HTMLInputElement>, v: number) => {
        v ? setSocialMedia(e.target.checked ? 1 : 0) : setSocialMedia(e.target.checked ? 0 : 1)
    }

    const changeAdvertising = (e: React.ChangeEvent<HTMLInputElement>, v: number) => {
        v ? setAdvertising(e.target.checked ? 1 : 0) : setAdvertising(e.target.checked ? 0 : 1)
    }



    const submit = () => {
        
    }

    return (
        <EditLayer setModel={setModel} maxWidth={640}>
            <>
                {errorMsg ?
                    <div className='items'>
                        <div className='error'>{errorMsg}</div>
                    </div>
                    : false
                }
                <div className='items'>
                    <h4>{t('ManageCookiePreferences')}</h4>
                    <div><Trans i18nKey='CookieLayerIntro' components={{ a: <a className='link-outline' href={Url(['privacystatement'], 'privacy', LanguageTag)} /> }} /></div>
                </div>
                <div className='items'>
                    <h4>{t('Required')}</h4>
                    <div>{t('CookieLayerRequired')}</div>
                </div>
                <div className='items'>
                    <h4>{t('Analytics')}</h4>
                    <div>{t('CookieLayerAnalytics')}</div>
                    <div className=''>
                        <Radio value={true} isChecked={ analytics === 1 ? true : false } onChange={(e) => { changeAnalytics(e, 1) }}>{t('Accept')}</Radio>
                        <Radio value={false} isChecked={ analytics === 0 ? true : false } onChange={(e) => { changeAnalytics(e, 0) }}>{t('Reject')}</Radio>
                    </div>
                </div>
                <div className='items'>
                    <h4>{t('SocialMedia')}</h4>
                    <div>{t('CookieLayerSocialMedia')}</div>
                    <div className=''>
                        <Radio value={true} isChecked={ socialMedia === 1 ? true : false } onChange={(e) => { changeSocialMedia(e, 1) }}>{t('Accept')}</Radio>
                        <Radio value={false} isChecked={ socialMedia === 0 ? true : false } onChange={(e) => { changeSocialMedia(e, 0) }}>{t('Reject')}</Radio>
                    </div>
                </div>
                <div className='items'>
                    <h4>{t('Advertising')}</h4>
                    <div>{t('CookieLayerAdvertising')}</div>
                    <div className=''>
                        <Radio value={true} isChecked={ advertising === 1 ? true : false } onChange={(e) => { changeAdvertising(e, 1) }}>{t('Accept')}</Radio>
                        <Radio value={false} isChecked={ advertising === 0 ? true : false } onChange={(e) => { changeAdvertising(e, 0) }}>{t('Reject')}</Radio>
                    </div>
                </div>
                <div className='layer-footer'>
                    <Button color='primary' type='button' size='lg' outline onClick={() => { submit() }}>{t('Save')}</Button>
                    <Button border='solid' size='lg' onClick={() => { enableScroll(); setModel('') }}>{t('Cancel')}</Button>
                </div>
            </>
        </EditLayer>
        
    );
}


export default CookieLayer