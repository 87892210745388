export const domain = 'roanchen.com'
export const scheme = 'https'
type CsrfProps = {
    header: string,
    param: string,
    token: string,
}
export const Csrf: CsrfProps = {
    header: 'X-CSRF-Token',
    param: document.head.querySelector('meta[name=csrf-param]')?.getAttribute('content') ||  '_csrf',
    token: document.head.querySelector('meta[name=csrf-token]')?.getAttribute('content') ||  ''
}

export const ICP = ''
export const lang = document.documentElement.lang.toLowerCase()

const Uri = {
    'cdn': `${scheme}://cdn.${domain}`,
    'static': `${scheme}://static.${domain}`,
    'www': `${scheme}://www.${domain}`,
    'wwwapi': `${scheme}://www.${domain}/api`,
    'account': `${scheme}://account.${domain}`,
    'accountapi': `${scheme}://account.${domain}/api`,
    'privacy': `${scheme}://privacy.${domain}`,
    'privacyapi': `${scheme}://privacy.${domain}/api`,
}
export default Uri;







