import React, { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import axios from 'axios';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { LanguageTag } from '@/Language'
import appUrl, { Csrf } from '@/config'
import Url from '@/functions/createurl';
import './common.less';
import { Col, Container, Row } from '@/rcui';
import CookieLayer from './CookieLayer';
import { disableScroll } from '@/functions/scroll';



const Footer: React.FC<{setShowCookieBar: React.Dispatch<React.SetStateAction<boolean>>}> = (props) => {
    const {setShowCookieBar} = props
    const { t } = useTranslation();
    const [footerJSX, setFooterJSX] = useState(<></>)
    const year = new Date().getFullYear().toString();

    // cookie 弹出框
    const [cookieLayerShow, setCookieLayerShow] = useState('')



    const pathname = LanguageTag === 'en' ? window.location.pathname : window.location.pathname.replace(/^\/[\w\-]{2,5}/, '')
    let url = encodeURIComponent(pathname+window.location.search.substring(0).replace('/'+LanguageTag, ''))

    if (pathname.match(/^\/language$/)) {
        url = window.location.search.substring(1).replace('return=', '')
    }



    useEffect(() => {
        axios.get(`${appUrl.www}/footer?language=${LanguageTag}`).then((res) => {
            let nav=[]
            for (const key in res.data.data) {
                if (false === res.data.data[key] instanceof Array) {
                    let navChild = []
                    for (const i in res.data.data[key]) {
                        navChild.push(
                            <React.Fragment key={i}><li><a className='link-outline' href={Url(res.data.data[key][i][0], res.data.data[key][i][1] ?? 'account', LanguageTag)} title={i}>{i}</a></li></React.Fragment>
                        )
                    }
                    nav.push(
                        <React.Fragment key={key}>
                            <Col xs={12} sm={6} md={4} lg={2}>
                                <h5 className='title'>{key}</h5>
                                <ul className='list'>{navChild}</ul>
                            </Col>
                        </React.Fragment>
                    )
                } else {
                    nav.push(<React.Fragment key={key}><li><a className='link-outline' href={Url(res.data.data[key][0], res.data.data[key][1] ?? 'www', LanguageTag)} title={key}>{key}</a></li></React.Fragment>)
                }
                setFooterJSX(<>{nav}</>)
            }
        })
    }, [])

    return (
        <React.Suspense fallback=''>
            <>
                <footer className='footer'>
                    <Container className='footer-wrapper'>
                        <Row gutter={16} className='footer-nav'>
                            {footerJSX}
                        </Row>
                        <div className='footer-bottom'>
                            <div className='bottom-locale'><a className='locale-link link-outline' href={Url(['language',`return=${url}`], 'account', LanguageTag)}>{t('LanguageName')}</a></div>
                            <div className='bottom-nav'>
                                <a className='link-outline' href={Url('sitemap', 'www', LanguageTag)}>{t('SiteMap')}</a>
                                <a className='link-outline' href={Url('contactus', 'support', LanguageTag)}>{t('ContactUs')}</a>
                                <a className='link-outline' href={Url('privacy-statement', 'privacy', LanguageTag)}>{t('PrivacyCookies')}</a>
                                <a className='link-outline' href={Url('terms-of-use', 'legal', LanguageTag)}>{t('TermsOfUse')}</a>
                                <a className='link-outline' href={Url('intellectualproperty/trademarks', 'legal', LanguageTag)}>{t('Trademark')}</a>
                                <a className='link-outline' onClick={(e) => {
                                    disableScroll()
                                    setCookieLayerShow('cookie');
                                }}>{t('ManageCookies')}</a>
                                {LanguageTag === 'zh-cn' ?
                                    (
                                        <>
                                            <a className='link-outline' href='https://beian.miit.gov.cn/' target='_blank'>皖ICP备2021003506号</a>
                                            {/* <a className='link-outline' href=''>公安网备000000000</a> */}
                                        </>
                                    ) : false
                                }
                                <span>&copy; {year} {t('Roanchen')}</span>
                            </div>
                        </div>
                    </Container>
                </footer>
                {cookieLayerShow ? <CookieLayer setModel={setCookieLayerShow} /> : false}
            </>
        </React.Suspense>
    )
};



export default Footer