import { createContext } from 'react'

export type DirectionType = 'ltr' | 'rtl' | undefined;

export interface ConfigProps {
    getPrefixClass: (suffixClass?: string, customizePrefixClass?: string) => string;
    direction?: DirectionType;
}


const defaultGetPrefixClass = (suffixClass?: string, customizePrefixClass?: string) => {
    if (customizePrefixClass) return customizePrefixClass;
  
    return suffixClass ? `rcui-${suffixClass}` : 'rcui';
  };

export const ConfigContext = createContext<ConfigProps>({
    getPrefixClass: defaultGetPrefixClass,
})

export const direction: string = 'ltr'

// size
// export type SizeType = 'sm' | 'md' | 'lg' | undefined;
