import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import appUrl from '@/config'
import axios from 'axios';


export interface StoreUser {
    online: boolean,
    uid: number,
    avatar: string,
    nickname: string,
    roanchenid: string,
    wallet: string,
    messages: number,
}


// export const loadData = createAsyncThunk('user/userinfo', async () => {
//     const response = await fetch(`${app.accountUrl}/online`);
//     return (await response.json()) as UserinfoType
// });


export const getOnline: any = createAsyncThunk('user/userinfo', async () => {
    const res = await axios.get(`${appUrl.account}/online`);
    
    return res.data
});


const userSlice = createSlice({
    name: 'userinfo',
    initialState: {
        online: false,
        uid: 0,
        avatar: '',
        nickname: '',
        roanchenid: '',
        wallet: '',
        messages: 0,
    },

    // 非异步调用
    reducers: {
        updateNickname: (state, action) => {
            state.nickname = action.payload
        }
    },

    // 异步调用
    extraReducers: (builder) => {
        builder.addCase(getOnline.fulfilled, (state, action) => {
            if (action.payload && action.payload !== '') {
                state.online = true
                state.uid = action.payload.uid
                state.avatar = action.payload.avatar
                state.nickname = action.payload.nickname
                state.roanchenid = action.payload.roanchenid
                state.wallet = action.payload.wallet
                state.messages = action.payload.messages
                return state
            }
        })
    },
})

export const {updateNickname} = userSlice.actions
export default userSlice.reducer
