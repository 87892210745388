import React from 'react'
import { LanguageCode } from '@/Language';
import { enableScroll } from './scroll';
import Uri from '@/config'

export const backPathname = (pathname: string) => {
    return (LanguageCode === 'en' ? '' : '/' + LanguageCode.toLowerCase()) + '/' + pathname
}


const EditLayer: React.FC<{ title?: string, children: JSX.Element, maxWidth?: number; back?: string, setModel: React.Dispatch<React.SetStateAction<string>> }> = (props) => {

    const {title, children, maxWidth, back, setModel} = props

    return (
        <div className='edit-layer'>
            <div className='layer-wrapper' style={{maxWidth:maxWidth}}>
                <div className='layer-header'>
                    <div className='title'>{title ?? <img className='layer-logo' src={`${Uri.cdn}/images/full_logo.svg`} alt='Roanchen' />}</div>
                    <div className='close'>
                        <div>
                            <a onClick={() => {
                                enableScroll();
                                if (back) {
                                    window.history.replaceState('', '', backPathname(back))
                                }
                                setModel('');
                            }}>
                                <span className='iconfont'>&#xe633;</span>
                            </a>
                        </div>
                    </div>
                    
                </div>
                <div className='layer-body scroll'>
                    {children}
                </div>
            </div>
            
        </div>
    )
}

export default EditLayer
