import React, { useContext } from 'react'
import classNames from 'classnames'
import { ConfigContext } from '../config'

import RowContext from './RowContext';

type ColSpanType = number | string;

type FlexType = number | 'none' | 'auto' | string;

export interface ColSize {
    flex?: FlexType;
    span?: ColSpanType;
    order?: ColSpanType;
    offset?: ColSpanType;
    push?: ColSpanType;
    pull?: ColSpanType;
}

export interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
    flex?: FlexType;
    span?: ColSpanType;
    order?: ColSpanType;
    offset?: ColSpanType;
    push?: ColSpanType;
    pull?: ColSpanType;
    xs?: ColSpanType | ColSize;
    sm?: ColSpanType | ColSize;
    md?: ColSpanType | ColSize;
    lg?: ColSpanType | ColSize;
    xl?: ColSpanType | ColSize;
    xxl?: ColSpanType | ColSize;
    PrefixClass?: string;
    expand?: boolean
    shrink?: boolean
}


function parseFlex(flex: FlexType): string {
    if (typeof flex === 'number') {
        return `${flex} ${flex} auto`;
    }

    if (/^\d+(\.\d+)?(px|em|rem|%)$/.test(flex)) {
        return `0 0 ${flex}`;
    }

    return flex;
}
const sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;
const Col = React.forwardRef<HTMLDivElement, ColProps>((props, ref) => {
    const { gutter, wrap, supportFlexGap } = React.useContext(RowContext);

    const {
        PrefixClass: customizePrefixClass,
        span,
        order,
        offset,
        push,
        pull,
        className,
        children,
        flex,
        style,
        expand,
        shrink,
        ...others
    } = props;

    const {getPrefixClass, direction} = useContext(ConfigContext)
    const prefixClass = getPrefixClass('col', customizePrefixClass);

    let sizeClassObj = {};
    sizes.forEach(size => {
        let sizeProps: ColSize = {};
        const propSize = props[size];
        if (typeof propSize === 'number') {
            sizeProps.span = propSize;
        } else if (typeof propSize === 'object') {
            sizeProps = propSize || {};
        }

        delete others[size];

        sizeClassObj = {
            ...sizeClassObj,
            [`${prefixClass}-${size}-${sizeProps.span}`]: sizeProps.span !== undefined,
            [`${prefixClass}-${size}-order-${sizeProps.order}`]: sizeProps.order || sizeProps.order === 0,
            [`${prefixClass}-${size}-offset-${sizeProps.offset}`]:
                  sizeProps.offset || sizeProps.offset === 0,
            [`${prefixClass}-${size}-push-${sizeProps.push}`]: sizeProps.push || sizeProps.push === 0,
            [`${prefixClass}-${size}-pull-${sizeProps.pull}`]: sizeProps.pull || sizeProps.pull === 0,
            [`${prefixClass}-rtl`]: direction === 'rtl',
        };
    });

    const classes = classNames(
        prefixClass,
        {
            [`${prefixClass}-${span}`]: span !== undefined,
            [`${prefixClass}-order-${order}`]: order,
            [`${prefixClass}-shrink`]: shrink,
            [`${prefixClass}-expand`]: expand,
            [`${prefixClass}-offset-${offset}`]: offset,
            [`${prefixClass}-push-${push}`]: push,
            [`${prefixClass}-pull-${pull}`]: pull,
        },
        className,
        sizeClassObj,
    );



    const mergedStyle: React.CSSProperties = {};
    // Horizontal gutter use padding
    if (gutter && gutter[0] > 0) {
        const horizontalGutter = gutter[0] / 2;
        
        mergedStyle.paddingLeft = horizontalGutter;
        mergedStyle.paddingRight = horizontalGutter;
    }
    // Vertical gutter use padding when gap not support
    if (gutter && gutter[1] > 0 && !supportFlexGap) {
        const verticalGutter = gutter[1] / 2;
        mergedStyle.paddingTop = verticalGutter;
        mergedStyle.paddingBottom = verticalGutter;
        
    }

 
    if (flex) {
        mergedStyle.flex = parseFlex(flex);

        // Hack for Firefox to avoid size issue
        // https://github.com/ant-design/ant-design/pull/20023#issuecomment-564389553
        if (wrap === false && !mergedStyle.minWidth) {
            mergedStyle.minWidth = 0;
        }
    }

    return (
        <div {...others} style={{ ...mergedStyle, ...style }} className={classes} ref={ref}>
            {children}
        </div>
    );
});


export default Col;