import React, { useContext } from 'react'
import classNames from 'classnames'
import toArray from 'rc-util/lib/Children/toArray';
import type { SizeType } from '../common/SizeContext';
import useFlexGapSupport from '../common/useFlexGapSupport';
import Item from './Item';
import { ConfigContext, direction as directionConfig } from '../config'


export const SpaceContext = React.createContext({
    latestIndex: 0,
    horizontalSize: 0,
    verticalSize: 0,
    supportFlexGap: false,
});

export type SpaceSize = SizeType | number;

export interface SpaceProps extends React.HTMLAttributes<HTMLDivElement> {
    prefixClass?: string;
    className?: string;
    style?: React.CSSProperties;
    size?: SpaceSize | [SpaceSize, SpaceSize];
    direction?: 'horizontal' | 'vertical';
    // No `stretch` since many components do not support that.
    align?: 'start' | 'end' | 'center' | 'baseline';
    split?: React.ReactNode;
    wrap?: boolean;
}


const spaceSize = {
    small: 8,
    middle: 16,
    large: 24,
};

function getNumberSize(size: SpaceSize) {
    return typeof size === 'string' ? spaceSize[size] : size || 0;
}



const Space: React.FC<SpaceProps> = props => {
    const {
        size,
        align,
        className,
        children,
        direction = 'horizontal',
        prefixClass: customizePrefixClass,
        split,
        style,
        wrap = false,
        ...otherProps
    } = props;
  
    const supportFlexGap = useFlexGapSupport();
  
    const [horizontalSize, verticalSize] = React.useMemo(
        () =>
            ((Array.isArray(size) ? size : [size, size]) as [SpaceSize, SpaceSize]).map(item =>
            getNumberSize(item),
            ),
        [size],
    );

    const childNodes = toArray(children, { keepEmpty: true });
  
    const mergedAlign = align === undefined && direction === 'horizontal' ? 'center' : align;
    const {getPrefixClass } = useContext(ConfigContext)
    const prefixClass = getPrefixClass('space', customizePrefixClass);
    const cn = classNames(
        prefixClass,
        `${prefixClass}-${direction}`,
        {
            [`${prefixClass}-rtl`]: directionConfig === 'rtl',
            [`${prefixClass}-align-${mergedAlign}`]: mergedAlign,
        },
        className,
    );
  
    const itemClassName = `${prefixClass}-item`;
  
    const marginDirection = directionConfig === 'rtl' ? 'marginLeft' : 'marginRight';
  
    // Calculate latest one
    let latestIndex = 0;
    const nodes = childNodes.map((child, i) => {
        if (child !== null && child !== undefined) {
            latestIndex = i;
        }
  
        const key = (child && child.key) || `${itemClassName}-${i}`;
    
        return (
            <Item
            className={itemClassName}
            key={key}
            direction={direction}
            index={i}
            marginDirection={marginDirection}
            split={split}
            wrap={wrap}
            >
                {child}
            </Item>
        );
    });
  
    const spaceContext = React.useMemo(
        () => ({ horizontalSize, verticalSize, latestIndex, supportFlexGap }),
        [horizontalSize, verticalSize, latestIndex, supportFlexGap ],
    );
  
    // =========================== Render ===========================
    if (childNodes.length === 0) {
        return null;
    }
  
    const gapStyle: React.CSSProperties = {};
  
    if (wrap) {
        gapStyle.flexWrap = 'wrap';
    
        // Patch for gap not support
        if (!supportFlexGap) {
            gapStyle.marginBottom = -verticalSize;
        }
    }
  
    if (supportFlexGap) {
        gapStyle.columnGap = horizontalSize;
        gapStyle.rowGap = verticalSize;
    }
  
    return (
        <div
            className={cn}
            style={{
            ...gapStyle,
            ...style,
            }}
            {...otherProps}
        >
            <SpaceContext.Provider value={spaceContext}>{nodes}</SpaceContext.Provider>
        </div>
    );
};
  
export default Space;