import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from '@/reportWebVitals';
import i18next, { use } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next, Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import store from '@/store'
import { Provider } from 'react-redux';
// import { useAppSelector } from '@/store'
// import { StoreUser } from '@/store/features/user'
import axios from 'axios';
import { LanguageTag } from '@/Language'
import appUrl, { Csrf } from '@/config'
import Url from '@/functions/createurl';
import '@/index.less';
import Header from './pages/common/Header';
import Footer from './pages/common/Footer';
import { Col, Container, Image, Row } from './rcui';
import Dropdown from '@/functions/dropdown';
import $ from 'jquery';
import Scrollbars from '@/scrollbars';
import Uri from '@/config';
import { Curtain, Follow } from './components';



i18next.use(Backend).use(initReactI18next).init({
    fallbackLng: LanguageTag,
    backend: {
        loadPath: `${appUrl.cdn}/language/www/${LanguageTag}.json`,
        crossDomain: true,
    }
});


const App: React.FC = () => {

    const { t } = useTranslation();
    const [showCookieBar, setShowCookieBar] = useState(false)
    const [headerHeight, setHeaderHeight] = useState(0)
    // const user: StoreUser = useAppSelector(state => state.userReducer as StoreUser)

    // const height = use100vh()
    // const bannerHeight = height ? height - 50 : '';
    let interval = useRef<NodeJS.Timer>()

    useEffect(() => {
        function slideshow(){
            let count=$('.banner>.item').length; //2
            let nowItem=$('.banner>.item.active');
            let nowIndex=nowItem.index();
            let next=(nowIndex+1) >= count ? $('.item:eq(0)') : $('.item:eq('+(nowIndex+1)+')');
            let prev=(nowIndex-1) <= 0 ? $('.item:eq('+(count-1)+')') : $('.item:eq('+(nowIndex-1)+')');
            
            next.css('z-index','3').addClass('active');
            nowItem.removeClass('active').css('z-index','1');
        
            next.fadeIn(1000, function() {
                nowItem.hide();
                nowItem.children('.info').fadeOut(1000,function(){
                    next.children('.info').fadeIn(1000);
                });
            });
        }


        if (!interval.current) {
            interval.current = setInterval(() => {
                slideshow();
            }, 10000);
        }
        
        
    },[])

    



    return (
        <React.Suspense fallback=''>
            <>
                <Header showCookieBar={showCookieBar} setShowCookieBar={setShowCookieBar} setHeaderHeight={setHeaderHeight} />
                <main className='main' style={{paddingTop: headerHeight}}>
                    <div className='banner-wrapper' style={{height: 'calc(100vh - 50px)'}}>
                        <div className='banner'>
                            <div className='item active' style={{display:'block'}}>
                                <div className='info' style={{display:'block'}}>
                                    <div className='text'><h3>软辰，你好</h3><h5>世界上的软件公司数不胜数，就如同浩瀚宇宙里的繁星</h5></div>
                                    <div className='buttons'><a className='arrows-btn box-arrows-btn btn-outline' href=''><span>了解更多</span></a></div>
                                </div>
                                <div className='banner-overlay'></div>
                                <div className='image'>
                                    <Image media={[{width: 992, src: `${Uri.static}/focus/home/16171669056929.jpg`}]} image={`${Uri.static}/focus/home/16171669056988.jpg`} title={'软辰，你好'} />
                                </div>
                            </div>
                            <div className='item'>
                                <div className='info'>
                                    <div className='text'><h3>软辰，你好</h3><h5>世界上的软件公司数不胜数，就如同浩瀚宇宙里的繁星</h5></div>
                                    <div className='buttons'><a className='arrows-btn box-arrows-btn btn-outline' href=''><span>了解更多</span></a></div>
                                </div>
                                <div className='banner-overlay'></div>
                                <div className='image'>
                                    <Image media={[{width: 992, src: `${Uri.static}/focus/home/16171669056929.jpg`}]} image={`${Uri.static}/focus/home/16171669056988.jpg`} title={'软辰，你好'} />
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <section>
                        <Container>
                            <Row className='section-header'>
                                <Col expand><h4>产品</h4></Col>
                                <Col><a className='more arrows-btn box-arrows-btn btn-outline' href=''><span>所有产品</span></a></Col>
                            </Row>
                            <Row gutter={16} className='home-products'>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='home-wrapper'>
                                        <a className='home-cover link-outline' href=''><img src={`${Uri.cdn}/images/placeholder.png`} /></a>
                                        <h5><a className='link-outline' href=''>名称名称名称名称名称名称</a></h5>
                                        <div className='description'>介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</div>
                                        <div><a className='arrows-btn link-outline' href=''><span>了解更多</span></a><a className='arrows-btn link-outline' href=''><span>预购</span></a></div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='home-wrapper'>
                                        <a className='home-cover link-outline' href=''><img src={`${Uri.cdn}/images/placeholder.png`} /></a>
                                        <h5><a className='link-outline' href=''>名称名称名称名称名称名称</a></h5>
                                        <div className='description'>介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</div>
                                        <div><a className='arrows-btn link-outline' href=''><span>了解更多</span></a><a className='arrows-btn link-outline' href=''><span>预购</span></a></div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='home-wrapper'>
                                        <a className='home-cover link-outline' href=''><img src={`${Uri.cdn}/images/placeholder.png`} /></a>
                                        <h5><a className='link-outline' href=''>名称名称名称名称名称名称</a></h5>
                                        <div className='description'>介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</div>
                                        <div><a className='arrows-btn link-outline' href=''><span>了解更多</span></a><a className='arrows-btn link-outline' href=''><span>预购</span></a></div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='home-wrapper'>
                                        <a className='home-cover link-outline' href=''><img src={`${Uri.cdn}/images/placeholder.png`} /></a>
                                        <h5><a className='link-outline' href=''>名称名称名称名称名称名称</a></h5>
                                        <div className='description'>介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</div>
                                        <div><a className='arrows-btn link-outline' href=''><span>了解更多</span></a><a className='arrows-btn link-outline' href=''><span>预购</span></a></div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section>
                        <Container>
                            <Row className='section-header'>
                                <Col expand><h4 id='news'>新闻</h4></Col>
                                <Col><a className='more arrows-btn box-arrows-btn btn-outline' href=''><span>所有新闻</span></a></Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={12} md={6} xxl={4}>
                                    <div className='home-wrapper'>
                                        <a className='home-cover link-outline' href=''><img src={`${Uri.cdn}/images/placeholder.png`} /></a>
                                        <h5><a className='link-outline' href=''>名称名称名称名称名称名称</a></h5>
                                        <div className='date'>2021-3-1</div>
                                        <div className='description'>介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} xxl={4}>
                                    <div className='home-wrapper home-news-md'>
                                        <div className='cover'><a className='home-cover link-outline' href=''><img src={`${Uri.cdn}/images/placeholder.png`} /></a></div>
                                        <div className='content'>
                                            <h5><a className='link-outline' href=''>名称名称名称名称名称名称</a></h5>
                                            <div className='date'>2021-3-1</div>
                                            <div className='description'>介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</div>
                                        </div>
                                    </div>
                                    <div className='home-wrapper home-news-md'>
                                        <div className='cover'><a className='home-cover link-outline' href=''><img src={`${Uri.cdn}/images/placeholder.png`} /></a></div>
                                        <div className='content'>
                                            <h5><a className='link-outline' href=''>名称名称名称名称名称名称</a></h5>
                                            <div className='date'>2021-3-1</div>
                                            <div className='description'>介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介介绍介绍介绍介绍介绍介绍介绍介绍介绍</div>
                                        </div>
                                    </div>
                                    <div className='home-wrapper home-news-md'>
                                        <div className='cover'><a className='home-cover link-outline' href=''><img src={`${Uri.cdn}/images/placeholder.png`} /></a></div>
                                        <div className='content'>
                                            <h5><a className='link-outline' href=''>名称名称名称名称名称名称</a></h5>
                                            <div className='date'>2021-3-1</div>
                                            <div className='description'>介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={12} xxl={4}>
                                    <Row gutter={16}>
                                        <Col xs={12} md={4} xxl={12}>
                                            <div className='home-wrapper home-news-right'>
                                                <div className='cover'><a className='home-cover link-outline' href=''><img src={`${Uri.cdn}/images/placeholder.png`} /></a></div>
                                                <div className='content'>
                                                    <h5><a className='link-outline' href=''>名称名称名称名称名称名称</a></h5>
                                                    <div className='date'>2021-3-1</div>
                                                    <div className='description'>介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4} xxl={12}>
                                            <div className='home-wrapper home-news-right'>
                                                <div className='cover'><a className='home-cover link-outline' href=''><img src={`${Uri.cdn}/images/placeholder.png`} /></a></div>
                                                <div className='content'>
                                                    <h5><a className='link-outline' href=''>名称名称名称名称名称名称</a></h5>
                                                    <div className='date'>2021-3-1</div>
                                                    <div className='description'>介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4} xxl={12}>
                                            <div className='home-wrapper home-news-right'>
                                                <div className='cover'><a className='home-cover link-outline' href=''><img src={`${Uri.cdn}/images/placeholder.png`} /></a></div>
                                                <div className='content'>
                                                    <h5><a className='link-outline' href=''>名称名称名称名称名称名称</a></h5>
                                                    <div className='date'>2021-3-1</div>
                                                    <div className='description'>介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section>
                        <Container>
                            <Row className='section-header'>
                                <Col expand><h4>商城</h4></Col>
                                <Col><a className='more arrows-btn box-arrows-btn btn-outline' href=''><span>前往商城</span></a></Col>
                            </Row>
                            <Row className='home-store-row' gutter={16}>
                                <Col xs={12} sm={6} xl={3}>
                                    <div className='home-store'>
                                        <a className='cover cover16-9 link-outline' href=''>
                                            <img src={`${Uri.cdn}/images/placeholder.png`} />
                                            <div className='info-bar'>
                                                <div className='name'>商品名称商名称商品名称商品名称商名称商品名称</div>
                                                <div className='prices-wrapper'>
                                                    <div className='discount'><span>-30%</span></div>
                                                    <div className='prices'>
                                                        <div className='original'><span>&yen; 99.00</span></div>
                                                        <div className='final-price'>&yen; 69.30</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} xl={3}>
                                    <div className='home-store'>
                                        <a className='cover cover16-9 link-outline' href=''>
                                            <img src={`${Uri.cdn}/images/placeholder.png`} />
                                            <div className='info-bar'>
                                                <div className='name'>商品名称商名称商品名称商品名称商名称商品名称</div>
                                                <div className='prices-wrapper'>
                                                    <div className='prices'>
                                                        <div className='final-price'>&yen; 69.30</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} xl={3}>
                                    <div className='home-store'>
                                        <a className='cover cover16-9 link-outline' href=''>
                                            <img src={`${Uri.cdn}/images/placeholder.png`} />
                                            <div className='info-bar'>
                                                <div className='name'>商品名称商名称商品名称商品名称商名称商品名称</div>
                                                <div className='prices-wrapper'>
                                                    <div className='discount'><span>-30%</span></div>
                                                    <div className='prices'>
                                                        <div className='original'><span>&yen; 99.00</span></div>
                                                        <div className='final-price'>&yen; 69.30</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} xl={3}>
                                    <div className='home-store'>
                                        <a className='cover cover16-9 link-outline' href=''>
                                            <img src={`${Uri.cdn}/images/placeholder.png`} />
                                            <div className='info-bar'>
                                                <div className='name'>商品名称商名称商品名称商品名称商名称商品名称</div>
                                                <div className='prices-wrapper'>
                                                    <div className='discount'><span>-30%</span></div>
                                                    <div className='prices'>
                                                        <div className='original'><span>&yen; 99.00</span></div>
                                                        <div className='final-price'>&yen; 69.30</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col className='home-store-wrapper' xs={6} sm={6} md={4} xl={2}>
                                    <div className='home-store'>
                                        <a className='cover cover4-3 link-outline' href=''>
                                            <img src={`${Uri.cdn}/images/placeholder.png`} />
                                            <div className='info-bar'>
                                                <div className='name'>商品名称商名称商品名称商品名称商名称商品名称</div>
                                                <div className='prices-wrapper'>
                                                    <div className='discount'><span>-30%</span></div>
                                                    <div className='prices'>
                                                        <div className='original'><span>&yen; 99.00</span></div>
                                                        <div className='final-price'>&yen; 69.30</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                                <Col className='home-store-wrapper' xs={6} sm={6} md={4} xl={2}>
                                    <div className='home-store'>
                                        <a className='cover cover4-3 link-outline' href=''>
                                            <img src={`${Uri.cdn}/images/placeholder.png`} />
                                            <div className='info-bar'>
                                                <div className='name'>商品名称商名称商品名称商品名称商名称商品名称</div>
                                                <div className='prices-wrapper'>
                                                    <div className='discount'><span>-30%</span></div>
                                                    <div className='prices'>
                                                        <div className='original'><span>&yen; 99.00</span></div>
                                                        <div className='final-price'>&yen; 69.30</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                                <Col className='home-store-wrapper' xs={6} sm={6} md={4} xl={2}>
                                    <div className='home-store'>
                                        <a className='cover cover4-3 link-outline' href=''>
                                            <img src={`${Uri.cdn}/images/placeholder.png`} />
                                            <div className='info-bar'>
                                                <div className='name'>商品名称商名称商品名称商品名称商名称商品名称</div>
                                                <div className='prices-wrapper'>
                                                    <div className='prices'>
                                                        <div className='final-price'>&yen; 69.30</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                                <Col className='home-store-wrapper' xs={6} sm={6} md={4} xl={2}>
                                    <div className='home-store'>
                                        <a className='cover cover4-3 link-outline' href=''>
                                            <img src={`${Uri.cdn}/images/placeholder.png`} />
                                            <div className='info-bar'>
                                                <div className='name'>商品名称商名称商品名称商品名称商名称商品名称</div>
                                                <div className='prices-wrapper'>
                                                    <div className='discount'><span>-30%</span></div>
                                                    <div className='prices'>
                                                        <div className='original'><span>&yen; 99.00</span></div>
                                                        <div className='final-price'>&yen; 69.30</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                                <Col className='home-store-wrapper' xs={6} sm={6} md={4} xl={2}>
                                    <div className='home-store'>
                                        <a className='cover cover4-3 link-outline' href=''>
                                            <img src={`${Uri.cdn}/images/placeholder.png`} />
                                            <div className='info-bar'>
                                                <div className='name'>商品名称商名称商品名称商品名称商名称商品名称</div>
                                                <div className='prices-wrapper'>
                                                    <div className='prices'>
                                                        <div className='final-price'>&yen; 69.30</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                                <Col className='home-store-wrapper' xs={6} sm={6} md={4} xl={2}>
                                    <div className='home-store'>
                                        <a className='cover cover4-3 link-outline' href=''>
                                            <img src={`${Uri.cdn}/images/placeholder.png`} />
                                            <div className='info-bar'>
                                                <div className='name'>商品名称商名称商品名称商品名称商名称商品名称</div>
                                                <div className='prices-wrapper'>
                                                    <div className='discount'><span>-30%</span></div>
                                                    <div className='prices'>
                                                        <div className='original'><span>&yen; 99.00</span></div>
                                                        <div className='final-price'>&yen; 69.30</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section>
                        <Container>
                            <Row className='section-header'>
                                <Col expand><h4>服务</h4></Col>
                            </Row>
                            <Curtain title='网站解决方案' description='依据网站不同的发展阶段，提供更合适的架构方案，有效降低网站的开发运维难度和整体 IT 成本，并保障网站的安全性和稳定性，节约大量的人力和资金投入。' buttons={[{text: '了解更多', url:Url('', 'service', LanguageTag)}]} images={[{media: 750, url: `${Uri.static}/service/images/16349663360300.png`},{url: `${Uri.static}/service/images/16349663360400.png`}]} />
                        </Container>
                    </section>
                    <Follow />
                    <Footer setShowCookieBar={setShowCookieBar} />
                </main>
                
            </>
        </React.Suspense>
    )
};


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <React.Suspense>
                <App />
            </React.Suspense>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();