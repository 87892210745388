import React, { useContext } from 'react'
import classNames from 'classnames'
import { ConfigContext } from '../config'

/**
 * 文字匹配
 * \u4e00-\u9fa5 中文
 * \uF900-\uFA2D 韩文
 * \u0800-\u4e00 日文
 */
const regexTwoChar = /^[\u4e00-\u9fa5\uF900-\uFA2D\u0800-\u4e00]{2}$/;
const isTwoChar = regexTwoChar.test.bind(regexTwoChar);


interface LoadingAttributes {
    stroke?: string;
    strokeWidth?: number
}

export interface BaseButtonProps {
    loading: boolean | LoadingAttributes
    prefixClass?: string;
    color?: 'default' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
    className?: string;
    outline?: boolean
    size?: 'sm' | 'lg' | 'xl'
    shape?: 'circle' | 'square'
    block?: boolean
    border?: 'solid' | 'dashed' | 'transparent'
    children?: React.ReactNode
}

// 锚点按钮
export type AnchorButtonProps = {
    href: string;
    target?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
} & BaseButtonProps & Omit<React.AnchorHTMLAttributes<any>, 'type' | 'onClick'>;


// 普通按钮
export type NativeButtonProps = {
    type?: 'submit' | 'button' | 'reset'
    onClick?: React.MouseEventHandler<HTMLElement>;
} & BaseButtonProps & Omit<React.ButtonHTMLAttributes<any>, 'type' | 'onClick'>;
export type ButtonProps = Partial<AnchorButtonProps & NativeButtonProps>;

// 节点内处理
function childNode(child: React.ReactNode, insertSpace?: boolean | undefined) {
    if (child == null) {
        return;
    }
    if (typeof child === 'string') {
        insertSpace === undefined && child.length === 2 ? insertSpace = true : insertSpace = undefined;
        const space = insertSpace ? ' ' : '';
        return insertSpace && isTwoChar(child) ? <span>{child.split('').join(space)}</span> : <span>{child}</span>
    }
    return child;
}

const Button = React.forwardRef<unknown, ButtonProps>((props, ref) => {
    const {
        loading = false,
        border,
        color,
        prefixClass: customizePrefixClass,
        className,
        outline,
        type,
        size,
        block,
        shape = 'circle',
        children,
        ...others
    } = props
    const {getPrefixClass, direction} = useContext(ConfigContext)
    const prefixClass = getPrefixClass('btn', customizePrefixClass);
    const buttonRef = (ref as any) || React.createRef<HTMLElement>();

    const classes = classNames(
        prefixClass,
        {
            [`${prefixClass}-${border}`]: border,
            [`${prefixClass}-${shape}`]: shape && shape !== 'circle',
            [`${prefixClass}-${size}`]: size,
            [`${prefixClass}-${color}`]: color,
            [`${prefixClass}-icon-only`]: !children && children !== 0,
            [`${prefixClass}-loading`]: loading,
            [`${prefixClass}-block`]: block,
            [`${prefixClass}-rtl`]: direction === 'rtl',
            [`${prefixClass}-outline`]: outline,
        },
        className
    )


    const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
        const { onClick, disabled } = props;
        if (disabled) {
            e.preventDefault();
            return;
        }
        (onClick as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>)?.(e);
    };

    // loading node
    const {
        stroke = 'currentColor',
        strokeWidth = 5
    } = loading as LoadingAttributes
    const LoadingNode = (
        <svg viewBox='25 25 50 50'>
            <circle style={{ stroke: stroke, strokeWidth: strokeWidth, fill: 'none' }} cx='50' cy='50' r='20' fill='none' />
            <animate attributeType='xml' attributeName='stroke-dasharray' from='1,360 360,360' to='360,360  1,360' dur='2s' repeatCount='indefinite'></animate>
            <animate attributeType='xml' attributeName='stroke-dashoffset' from='0' to='-150' dur='2s' repeatCount='indefinite'></animate>
            <animateTransform attributeType='xml' attributeName='transform' type='rotate' from='0' to='360' dur='2s' repeatCount='indefinite'></animateTransform>
        </svg>
    )

    const iconNode = (
        loading ? <span className='btn-icon'>{LoadingNode}</span> : ''
    )

    const child = children ? childNode(children) : null;


    
    if (props.href !== undefined) {
        return (
            <a {...(others as AnchorButtonProps)} className={classes} onClick={handleClick} ref={buttonRef}>
                {iconNode}
                {child}
            </a>
        );
    }
    return (
        <button
            {...(others as NativeButtonProps)}
            type={type}
            className={classes}
            onClick={handleClick}
            ref={buttonRef}
        >
            {iconNode}
            {child}
        </button>
    )
});

Button.displayName = 'Button';
export default Button;