import React, { forwardRef, useState, useRef, useEffect, useContext } from 'react'
import classNames from 'classnames'
import { ConfigContext } from '../config'



type variantType = 'inside' | 'outline';
type sizeType = 'default' | 'sm' | 'md' | 'lg' | 'xl';
type statusType = 'success' | 'warning' | 'error' | '';


export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    variant?: variantType;
    size?: sizeType;
    label?: string | React.ReactNode;
    block?: boolean;
    dateFormat?: string;
    status?: statusType | string;
    prefixClass?: string;
    LeftText?: string;
    dateDisplay?: string;
    setDate?: React.Dispatch<React.SetStateAction<string>>
}

// export interface InputRef {
//     focus: () => void;
//     blur: () => void;
//     setSelectionRange: (
//         start: number,
//         end: number,
//         direction?: 'forward' | 'backward' | 'none',
//     ) => void;
//     select: () => void;
//     input?: HTMLInputElement | null;
// }

const Input = forwardRef<HTMLDivElement, InputProps>((props, ref) => {
    const {
        id,
        variant,
        label,
        size,
        type,
        className,
        autoComplete,
        autoFocus,
        color,
        LeftText,
        dateDisplay,
        value,
        block,
        dateFormat,
        disabled,
        placeholder,
        prefixClass: customizePrefixClass,
        status,
        setDate,
        onBlur,
        onChange,
        onFocus,
        onKeyDown,
        onKeyUp,
        onClick,
        ...rest
    } = props;
    const { getPrefixClass, direction } = useContext(ConfigContext);
    const prefixClass = getPrefixClass('input', customizePrefixClass);
    const inputRef = useRef<HTMLInputElement>(null);
    const [currentStatus, setCurrentStatus] = useState(props.status);
    const [currentType, setCurrentType] = useState(type);
    const [focus, setFocus] = useState(autoFocus ? true : false);
    const [hasValue, setHasValue] = useState(value ? true : false);
    const [placeholderContent, setPlaceholderContent] = useState(variant === 'outline' ? '' : placeholder);
    const [dateValue, setDateValue] = useState('');
    const [cursor, setCursor] = useState(0);// 光标位置

    // const [LeftText, setBrforeText] = useState(LeftText);
    const variantProp = variant ?? 'inside'

    const wrapperClasses = classNames(
        [`${prefixClass}-${variantProp}`],
        {
            [`${prefixClass}-block`]: block,
            [`${prefixClass}-${size}`]: size,
            [`${prefixClass}-rtl`]: direction === 'rtl',
            [`${prefixClass}-disabled`]: disabled,
        }
    )

    const formControlClasses = classNames(
        `${prefixClass}-form-control`,
        {
            [`${prefixClass}-${currentStatus}`]: currentStatus && currentStatus !== '' ,
            [`${prefixClass}-focused`]: focus,
            [`${prefixClass}-${size}`]: size && size !== 'default',
            [`${prefixClass}-disabled`]: disabled,
        }
    )

    const labelControlClasses = classNames(
        `${prefixClass}-label`,
        {
            [`${prefixClass}-${currentStatus}`]: currentStatus !== undefined && currentStatus !=='',
            [`${prefixClass}-focused`]: focus,
            [`${prefixClass}-shrink`]: hasValue,
            [`${prefixClass}-${size}`]: size && size !== 'default',
            [`${prefixClass}-disabled`]: disabled,
        }
    )



    const inputClasses = classNames(
        prefixClass,
        {
            [`${prefixClass}-${size}`]: size && size !== 'default',
        },
        className
    )

    
    const legendClasses = classNames(
        prefixClass + 'legend',
        {
            [`${prefixClass}-legend-shrink`]: !focus && !hasValue,
            [`${prefixClass}-${size}`]: size && size !== 'default',
        }
    )

    const leftTextClasses = classNames(
        `${prefixClass}-left-text`,
        {
            [`show`]: focus || hasValue
        },
    )

    // ===================== 删除 type=password 中的值 =====================
    const removePasswordTimeoutRef = useRef<number[]>([]);
    const removePasswordTimeout = () => {
        removePasswordTimeoutRef.current.push(
            window.setTimeout(() => {
                if (
                    inputRef.current &&
                    inputRef.current?.getAttribute('type') === 'password' &&
                    inputRef.current?.hasAttribute('value')
                ) {
                    inputRef.current?.removeAttribute('value');
                }
            }),
        );
    };
    useEffect(() => {
        removePasswordTimeout();
        return () => {
            removePasswordTimeoutRef.current.forEach(item => window.clearTimeout(item));
        }
    }, []);
    


    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        removePasswordTimeout();

        setFocus(true);
        if (variant === 'outline' && !hasValue) {
            setPlaceholderContent(placeholder)
        }

        if (dateFormat && dateDisplay) {
            if (value === '') {
                setCursor(0)
            }
            setDateValue(dateDisplay);
            setTimeout(() => { e.target.selectionStart = cursor; e.target.selectionEnd = cursor; });
        }

        onFocus?.(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        removePasswordTimeout();
        setFocus(false);
        if (variant === 'outline' && !hasValue) {
            setPlaceholderContent('');
        }

        if (dateFormat && value === '') {
            setDateValue('');
            setHasValue(false)
        }

        onBlur?.(e);
    };

    const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
        if (dateFormat) {
            let element = e.target as HTMLInputElement
            if (value === '') {
                setCursor(0)
                setTimeout(() => { element.selectionStart = cursor; element.selectionEnd = cursor; });
            } else {
                setCursor(element.selectionStart as number)
                setTimeout(() => { element.selectionStart = element.selectionStart; element.selectionEnd = element.selectionStart; });
            }
        }
        onClick?.(e)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length) {
            setHasValue(true)
        } else {
            setHasValue(false)
        }
        onChange?.(e);
    };


    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(dateFormat && dateDisplay){
            // let element = e.target as HTMLInputElement;
            let date = value as string;
            // 验证输入是否为数字
            let inputKey = e.key;
            let cursorOffset: number = 0;


            // 显示日期，删除取值填充
            let dateDisplayArray = [];
            for (let i of dateDisplay) {
                dateDisplayArray.push(i);
            }

            // 将日期格式化为数组 方便通过下标直接取值
            let dateFormatArray = [];
            for (let i of dateFormat) {
                dateFormatArray.push(i);
            }
            

            // 获得日期索引数组 y1 y2 y3 y4 ...
            let formatIndexArray = [];
            let y = 0;
            let m = 0;
            let d = 0;

            for (let k in dateFormatArray) {
                switch (dateFormatArray[k]) {
                    case 'y':
                        y += 1;
                        formatIndexArray.push(dateFormatArray[k] + y);
                        break;
                    case 'm':
                        m += 1;
                        formatIndexArray.push(dateFormatArray[k] + m);
                        break;
                    case 'd':
                        d += 1;
                        formatIndexArray.push(dateFormatArray[k] + d);
                        break;
                }
                if (!dateFormatArray[k].match(/^[a-z]$/)) {
                    formatIndexArray.push(dateFormatArray[k]);
                }
            }

            if(inputKey.match(/\d/)) {
                switch (formatIndexArray[cursor]) {
                    case 'y1':
                        if (-1 === ['1', '2'].indexOf(inputKey)) {  return false; }
                        break;
                    case 'y2':
                        if (-1 === ['0', '1', '8', '9'].indexOf(inputKey)) {  return false; }
                        break;
                    case 'y3':
                        if (!inputKey.match(/^\d$/)) { return false; };
                        break;
                    case 'y4':
                        if (!inputKey.match(/^\d$/)) { return false; };
                        break;
                    case 'm1':
                        if (-1 === ['0', '1'].indexOf(inputKey)) { return false; }
                        break;
                    case 'm2':
                        if (!inputKey.match(/^\d$/)) { return false; }
                        break;
                    case 'd1':
                        if (-1 === ['0', '1', '2', '3'].indexOf(inputKey)) {  return false; }
                        break;
                    case 'd2':
                        if (!inputKey.match(/^\d$/)) { return false; }
                        break;
                }
            }


            //ArrowLeft ArrowRight  Backspace

            if (inputKey === 'ArrowLeft' || inputKey === 'Backspace') {
                if (cursor - 1 < 0) {
                    return false;
                }
                if (!dateFormatArray[cursor - 1].match(/[a-z0-9]/)) {
                    cursorOffset = -2
                } else {
                    cursorOffset = -1
                }
            }


            if (inputKey.match(/\d/) || inputKey === 'ArrowRight') {
                if (cursor === dateFormatArray.length) {
                    return false;
                }
                if (cursor+1 < dateFormatArray.length && !dateFormatArray[cursor + 1].match(/[a-z0-9]/)) {
                    cursorOffset = 2
                }else{
                    cursorOffset = 1
                }
            }

        
            // 输入数字
            if (inputKey.match(/\d/) && dateDisplayArray[cursor] !== undefined) {
                if (date === '') {
                    date = dateDisplay;
                }
                setDate && setDate(date.substring(0, cursor) + inputKey + date.substring(cursor + 1, date.length))
            }
            
            // 删除
            if (inputKey === 'Backspace') {
                setDate && setDate(date.substring(0, cursor + cursorOffset) + dateDisplayArray[cursor + cursorOffset] + date.substring(cursor + cursorOffset + 1, date.length))
                
            }

            // 删除
            if (inputKey === 'Delete') {
                setDate && setDate(date.substring(0, cursor) + dateDisplayArray[cursor] + date.substring(cursor + 1, date.length))
            }
            
            if (inputKey === 'ArrowUp') {
                setCursor(0)
            } else if (inputKey === 'ArrowDown') {
                setCursor(dateDisplayArray.length)
            } else {
                setCursor(cursor + cursorOffset)
            }
            
        }
        onKeyDown?.(e)
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (dateFormat) {
            let element = e.target as HTMLInputElement;
            element.selectionStart = cursor;
            element.selectionEnd = cursor;
        }
        onKeyUp?.(e)
    }


    useEffect(() => {
        setCurrentStatus(status)
    }, [status])
    
    useEffect(() => {
        setCurrentType(type)
    }, [type])


    useEffect(() => {
        if (value) {
            setHasValue(true)
        } else {
            setHasValue(false)
        }
    }, [value])
    return (
        <div className={`${prefixClass}-wrapper ` + wrapperClasses} ref={ref}>
            {label && variant !== 'inside' ?
                (
                    <label
                        className={labelControlClasses}
                        htmlFor={id}
                    >
                        {label}
                    </label>
                ) : false
            }
            <div className={formControlClasses}>
                {label && variant === 'inside' ?
                    (
                        <label
                            className={labelControlClasses}
                            htmlFor={id}
                        >
                            {typeof label === 'string' ? <span>{label}</span> : label}
                        </label>
                    ) : false
                }

                {LeftText && (
                    <span className={leftTextClasses}>{LeftText}</span>
                )}

                <input
                    {...rest as React.InputHTMLAttributes<HTMLInputElement>}
                    id={id}
                    autoComplete={autoComplete}
                    autoFocus={autoFocus}
                    type={currentType}
                    className={inputClasses}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    value={value===''&&dateValue?dateValue:value}
                    disabled={disabled}
                    placeholder={placeholderContent}
                    aria-hidden='true'
                    ref={inputRef}
                />
                {variant === 'outline' ?
                    (
                        <fieldset className={`${prefixClass}-fieldset`}>
                            <legend className={legendClasses}><span>{label}</span></legend>
                        </fieldset>
                    ) : false
                }
            </div>
        </div>
    );
});


Input.displayName = 'Input';
export default Input;