import { domain } from '@/config'
const Url = (route: string | Array<string>, host?: string, language ?: string | boolean, https?: boolean): string => {
    let routeString = '';

    if ((route === '' || route === '/') && host === undefined) {
        return '/';
    }
    if (host !== undefined && typeof host === 'string' && host !== '') {
        routeString = host?`//${host}.${domain}`:`//${domain}`;
    }
    if (typeof https === 'boolean') {
        if (https === true) {
            routeString = `https:${routeString}`;
        } else if(https === false) {
            routeString = `http://${routeString}`;
        }

    } else {
        if (host === undefined) {
            routeString = `${routeString}`;
            
        } else {
            routeString = `${window.location.protocol}${routeString}`;
        }
    }
    if (typeof language === 'string' && language.length > 0 && language !== 'en') {
        routeString += `/${language}`;
    }
    if (typeof route === 'object') {
        route.forEach((element, index) => {
            if (0 === index) {
                element === '/' ? routeString += '/' : routeString += `/${element}`;
            } else {
                if (1 === index) {
                    routeString += `?${element}`;
                } else {
                    routeString += `&${element}`;
                }
            }
        });
    } else if (typeof route === 'string') {
        routeString += `/${route}`;
    }
    return routeString;
};
export default Url;

