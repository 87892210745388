import React, { useState, useContext, useEffect } from 'react'
import classNames from 'classnames';
// import RcRadio from 'rc-checkbox';
// import { FormItemInputContext } from '../form/context';
// import { GroupContext } from './Group';
import { ConfigContext } from '../config'
// import warning from '../_util/warning';

export interface AbstractRadioProps<T> {
    prefixClass?: string;
    className?: string;
    defaultChecked?: boolean;
    checked?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
    // onChange?: (e: T) => void;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onClick?: React.MouseEventHandler<HTMLElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLElement>;
    onKeyPress?: React.KeyboardEventHandler<HTMLElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
    value?: any;
    tabIndex?: number;
    name?: string;
    children?: React.ReactNode;
    id?: string;
    autoFocus?: boolean;
    type?: string;
    skipGroup?: boolean;
    isChecked?: boolean;
}

export interface RadioChangeEventTarget extends RadioProps {
    checked: boolean;
}

export interface RadioChangeEvent {
    target: RadioChangeEventTarget;
    stopPropagation: () => void;
    preventDefault: () => void;
    nativeEvent: MouseEvent;
}


export type RadioProps = {
    indeterminate?: boolean;
} & AbstractRadioProps<RadioChangeEvent>


const RenderRadio: React.ForwardRefRenderFunction<unknown, RadioProps> = (props, ref) => {
    const {
        prefixClass: customizePrefixClass,
        className,
        children,
        indeterminate = false,
        style,
        name,
        onMouseEnter,
        onMouseLeave,
        skipGroup = false,
        isChecked,
        ...others
    } = props
    
    const {getPrefixClass, direction} = useContext(ConfigContext)
    const prefixClass = getPrefixClass('checkbox', customizePrefixClass);
    // const checkboxGroup = React.useContext(GroupContext);
    // const { isFormItemInput } = useContext(FormItemInputContext);

    // const prevValue = React.useRef(others.value);
    const checkboxProps: RadioProps = { ...others };
    const [checked, setChecked] = useState(isChecked ? true : false)

    useEffect(() => {
        setChecked(isChecked as boolean)
    }, [isChecked])

    // React.useEffect(() => {
    //     checkboxGroup?.registerValue(others.value);
    //     warning(
    //     'checked' in others || !!checkboxGroup || !('value' in others),
    //     'Radio',
    //     '`value` is not a valid prop, do you mean `checked`?',
    //     );
    // }, []);

    // React.useEffect(() => {
    //     if (skipGroup) {
    //     return;
    //     }
    //     if (restProps.value !== prevValue.current) {
    //     checkboxGroup?.cancelValue(prevValue.current);
    //     checkboxGroup?.registerValue(restProps.value);
    //     prevValue.current = restProps.value;
    //     }
    //     return () => checkboxGroup?.cancelValue(restProps.value);
    // }, [restProps.value]);

    // if (checkboxGroup && !skipGroup) {
    //     checkboxProps.onChange = (...args) => {
    //     if (restProps.onChange) {
    //         restProps.onChange(...args);
    //     }
    //     if (checkboxGroup.toggleOption) {
    //         checkboxGroup.toggleOption({ label: children, value: restProps.value });
    //     }
    //     };
    //     checkboxProps.name = checkboxGroup.name;
    //     checkboxProps.checked = checkboxGroup.value.indexOf(restProps.value) !== -1;
    //     checkboxProps.disabled = restProps.disabled || checkboxGroup.disabled;
    // }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange } = props;
        if (e.target.checked) {
            setChecked(true)
            
        } else {
            setChecked(false)
        }


        (onChange as React.ChangeEventHandler<HTMLInputElement>)?.(e);
    };



    const lableClass = classNames(
        {
        [`${prefixClass}`]: true,
        [`${prefixClass}-rtl`]: direction === 'rtl',
        [`${prefixClass}-checked`]: checked,
        [`${prefixClass}-disabled`]: checkboxProps.disabled,
        // [`${prefixClass}-in-form-item`]: isFormItemInput,
        },
        className,
    );
    const checkboxClass = classNames(
        `${prefixClass}-wrapper`,
        {
            // [`${prefixClass}-indeterminate`]: indeterminate,
        }
    );
    const checkboxInputClass = classNames(
        `${prefixClass}-input`,
        {
            [`${prefixClass}-indeterminate`]: indeterminate,
        }
    );
    const ariaChecked = indeterminate ? 'mixed' : undefined;
    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
        className={lableClass}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        >
            <span className={ checkboxClass }>
                <input
                    type = "radio"
                    checked = {checked}
                    onChange = {onChange}
                    value = {others.value}
                    disabled = {others.disabled}
                    className={checkboxInputClass}
                    name={name}
                    // ref = {ref}
                />
                <span className={ `${prefixClass}-border` }></span>
                <span className={ `${prefixClass}-icon` }></span>
            </span>
        {children !== undefined && <span>{children}</span>}
        </label>
    );
};

const Radio = React.forwardRef<unknown, RadioProps>(RenderRadio);

Radio.displayName = 'Radio';

export default Radio;
