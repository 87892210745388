import React, { useState, useEffect, useContext } from 'react'
import classNames from 'classnames'
import { ConfigContext } from '../config'


export interface SliderBaseProps {
    prefixClass?: string;
    reverse?: boolean;
    min?: number;
    max?: number;
    step?: null | number;
    dots?: boolean;
    included?: boolean;
    disabled?: boolean;
    vertical?: boolean;
    tipFormatter?: null | ((value?: number) => React.ReactNode);
    className?: string;
    id?: string;
    style?: React.CSSProperties;
    tooltipVisible?: boolean;
    getTooltipPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    autoFocus?: boolean;
}
  
export interface SliderSingleProps extends SliderBaseProps {
    range?: false;
    value?: number;
    defaultValue?: number;
    onChange?: (value: number) => void;
    onAfterChange?: (value: number) => void;
    handleStyle?: React.CSSProperties;
    trackStyle?: React.CSSProperties;
}
  
export interface SliderRangeProps extends SliderBaseProps {
    range: true | SliderRange;
    value?: [number, number];
    defaultValue?: [number, number];
    onChange?: (value: [number, number]) => void;
    onAfterChange?: (value: [number, number]) => void;
    handleStyle?: React.CSSProperties[];
    trackStyle?: React.CSSProperties[];
}


interface SliderRange {
    draggableTrack?: boolean;
}

const Slider = React.forwardRef<unknown, SliderSingleProps | SliderRangeProps>((props, ref: any) => {

    const {
        prefixClass: customizePrefixClass,
        range,
        className,
        defaultValue,
        ...rest
    } = props

   
    const { getPrefixClass, direction } = useContext(ConfigContext);
    const prefixClass = getPrefixClass('slider', customizePrefixClass);
    const [left, setLeft] = useState(0)
    
    const classes = classNames(
        prefixClass,
        {
            [`${prefixClass}-rtl`]: direction === 'rtl',
            
        },
        className
    )


    const Thumbhandle = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        // e.clientX - 

        // setLeft(e.clientX)
    }

    useEffect(() => {
        if (typeof (defaultValue) === 'number') {
            setLeft(defaultValue)
        }
    }, [])

    return (
        <>
            <span className={classes}>
                <span className={`${prefixClass}-rail`}></span>
                <span className={`${prefixClass}-track`} style={{width:left}}></span>
                <span className={`${prefixClass}-thumb`} style={{ left: left }} onMouseDown={(e) => { Thumbhandle(e) }}></span>
            </span>
        </>
    );
})



Slider.displayName = 'Slider';
export default Slider;