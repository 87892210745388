var winX = null;

var winY = null;

window.addEventListener('scroll', function () { if (winX !== null && winY !== null) { window.scrollTo(winX, winY); } });

export const disableScroll = () => {
    winX = window.scrollX
    winY = window.scrollY
}

export const enableScroll = () => {
    winX = null
    winY = null
}

