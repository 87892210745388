import Uri from '@/config';
import './follow.less'
import { Container, Row, Col } from '@/rcui'
import { useTranslation } from 'react-i18next';


const Follow = (() => {
    const { t } = useTranslation();
    return (
        <Container className='follow-wrapper'>
            <Row gutter={16}>
                <Col shrink><span className='follow-title'>{t('FollowRoanchen')}</span></Col>
                <Col expand>
                    <ul className='follow-list'>
                        <li><a className='link-outline' target='_blank' href='https://space.bilibili.com/1296975105'><img className='follow-icon' src={`${Uri.cdn}/images/bilibili.svg`} /></a></li>
                        <li><a className='link-outline' target='_blank' href='https://www.facebook.com/RoanchenCorp/'><img className='follow-icon' src={`${Uri.cdn}/images/facebook.svg`} /></a></li>
                        <li><a className='link-outline' target='_blank' href=''><img className='follow-icon' src={`${Uri.cdn}/images/instagram.svg`} /></a></li>
                        <li><a className='link-outline' target='_blank' href='https://twitter.com/RoanchenCorp'><img className='follow-icon' src={`${Uri.cdn}/images/twitter.svg`} /></a></li>
                        <li><a className='link-outline' target='_blank' href=''><img className='follow-icon' src={`${Uri.cdn}/images/vk.svg`} /></a></li>
                        <li><a className='link-outline' target='_blank' href={`${Uri.www}/wechat`}><img className='follow-icon' src={`${Uri.cdn}/images/wechat.svg`} /></a></li>
                        <li><a className='link-outline' target='_blank' href='https://weibo.com/p/1006067573318678'><img className='follow-icon' src={`${Uri.cdn}/images/weibo.svg`} /></a></li>
                        <li><a className='link-outline' target='_blank' href='https://www.youtube.com/channel/UCpe0q9lsO7f3q4wSfeHIMFg'><img className='follow-icon' src={`${Uri.cdn}/images/youtube.svg`} /></a></li>
                    </ul>
                </Col>
            </Row>
        </Container>
    )

});

export default Follow;

