import React, { useContext } from 'react'
import classNames from 'classnames'
import './curtain.less'
import { Container } from '@/rcui'


export interface curtainButton {
    text: string
    url: string
}

export interface curtainImage {
    media?: number
    url: string
}

export interface CurtainProps extends React.HTMLAttributes<HTMLDivElement>{
    title: string
    description: string
    buttons?: curtainButton[]
    images: curtainImage[]
    container?: boolean
}

const Curtain = ((props:CurtainProps) => {
    const {
        title,
        description,
        buttons,
        images,
        container
    } = props



    return (
        <div className='rc-curtain'>
            {container ?
                <>
                    <Container>
                        <div className='image'>
                            <picture>
                                {images.map((img, i) => {
                                    if (i !== images.length - 1) {
                                        return <source key={i} media={`(min-width: ${img.media}px)`} srcSet={img.url} />
                                    } else {
                                        return <img key={i} src={img.url}  alt={title}/>
                                    }
                                })}
                            </picture>
                        </div>
                        <div className='wrapper'>
                            <div className='content'>
                                <h3>{title}</h3>
                                <div>{description}</div>
                            </div>
                            {buttons  ?
                                <div className='buttons'>
                                    {buttons.map((button, i) => {
                                        return (
                                            <a key={i} className='more arrows-btn box-arrows-btn btn-outline' href={button.url}><span>{button.text}</span></a>
                                        )
                                    })}
                                </div>
                                : ''
                            }
                        </div>
                    </Container>
                </>
            :
            <>
                <div className='image'>
                    <picture>
                        {images.map((img, i) => {
                            if (i !== images.length - 1) {
                                return <source key={i} media={`(min-width: ${img.media}px)`} srcSet={img.url} />
                            } else {
                                return <img key={i} src={img.url}  alt={title}/>
                            }
                        })}
                    </picture>
                </div>
                <div className='wrapper'>
                    <div className='content'>
                        <h3>{title}</h3>
                        <div>{description}</div>
                    </div>
                    {buttons ?
                        <div className='buttons'>
                            {buttons.map((button, i) => {
                                return (
                                    <a key={i} className='more arrows-btn box-arrows-btn btn-outline' href={button.url}><span>{button.text}</span></a>
                                )
                            })}
                        </div>
                        : ''
                    }
                </div>
            </>
            }
            
        </div>
    )

});

export default Curtain;

