import React, { useContext } from 'react'
import classNames from 'classnames'
import { ConfigContext } from '../config'


export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    PrefixClass?: string;
    fluid?: boolean
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>((props, ref) => {
    const {
        PrefixClass: customizePrefixClass,
        className,
        fluid,
        children,
        style,
        ...others
    } = props

    const {getPrefixClass, direction} = useContext(ConfigContext)
    const prefixClass = getPrefixClass('container', customizePrefixClass);

    const classes = classNames(
        prefixClass,
        {
            [`fluid`]: fluid === true
        },
        className
    );





    return (
        <>
            <div className={classes}>
                {children}
            </div>
        </>
    )
});



export default Container